import {
  getMonthUsers,
  getTodayUsers,
  getUsersLast12Months,
  getWeekUsers,
} from "@/api/adminApi";
import { IcProjectCompleted, IcProjectInreview } from "@/assets/Icons";
import ProjectOverViewCard from "@/components/Dashboard/ProjectOverViewCard";
import Spinner from "@/components/UI/Spinner";
import BalanceCard from "@components/BalanceCard";
import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface DashboardStates {
  inReviewProjectsCount: number;
  totalProjectsCount: number;
  totalFinancingReceived: number;
}

const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [todayUsers, setTodayUsers] = useState(0);
  const [weekUsers, setWeekUsers] = useState(0);
  const [monthUsers, setMonthUsers] = useState(0);
  const [chartData, setChartData] = useState([]);

  // ~ getting the today user counts
  useEffect(() => {
    const handleGetTodayUser = async () => {
      setIsLoading(true);
      await getTodayUsers().then((res) => {
        if (res.success) {
          setTodayUsers(res.userCount);
        } else {
          setTodayUsers(0);
        }
        setIsLoading(false);
      });
    };

    handleGetTodayUser();
  }, []);

  // ~ getting the week user counts
  useEffect(() => {
    const handleGetWeekUser = async () => {
      setIsLoading(true);
      await getWeekUsers().then((res) => {
        if (res.success) {
          setWeekUsers(res.userCount);
        } else {
          setWeekUsers(0);
        }
        setIsLoading(false);
      });
    };

    handleGetWeekUser();
  }, []);

  // ~ getting the month user counts
  useEffect(() => {
    const handleGetMonthUser = async () => {
      setIsLoading(true);
      await getMonthUsers().then((res) => {
        if (res.success) {
          setMonthUsers(res.userCount);
        } else {
          setMonthUsers(0);
        }
        setIsLoading(false);
      });
    };

    handleGetMonthUser();
  }, []);

  // ~ getting the getUsersLast12Months
  useEffect(() => {
    const handleGet12MonthUser = async () => {
      setIsLoading(true);
      await getUsersLast12Months().then((res) => {
        console.log("🐱‍👤✨ ~ awaitgetUsersLast12Months ~ res:", res);

        if (res.success) {
          setChartData(res.results);
        } else {
          setChartData([]);
        }
        setIsLoading(false);
      });
    };

    handleGet12MonthUser();
  }, []);

  const [dashboardStates, setDashboardStates] = useState<DashboardStates>({
    inReviewProjectsCount: 0,
    totalProjectsCount: 0,
    totalFinancingReceived: 0,
  });

  const data = [
    {
      name: "January",
      users: 3500,
    },
    {
      name: "Feburary",
      users: 3000,
    },
    {
      name: "March",
      users: 2000,
    },
    {
      name: "April",
      users: 2780,
    },
    {
      name: "May",
      users: 1890,
    },
    {
      name: "June",
      users: 2390,
    },
    {
      name: "July",
      users: 3490,
    },
    {
      name: "August",
      users: 3490,
    },
    {
      name: "September",
      users: 3490,
    },
    {
      name: "October",
      users: 3490,
    },
    {
      name: "November",
      users: 3490,
    },
    {
      name: "December",
      users: 3490,
    },
  ];

  const data01 = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  const data02 = [
    { name: "A1", value: 100 },
    { name: "A2", value: 300 },
    { name: "B1", value: 100 },
    { name: "B2", value: 80 },
    { name: "B3", value: 40 },
    { name: "B4", value: 30 },
    { name: "B5", value: 50 },
    { name: "C1", value: 100 },
    { name: "C2", value: 200 },
    { name: "D1", value: 150 },
    { name: "D2", value: 50 },
  ];

  // Rendered UI with fetched data
  return (
    <div className="flex flex-col gap-4">
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Spinner />
        </div>
      ) : (
        <>
          {/* <GetCapitalBanner /> */}
          <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 min-h-[145px]">
            <BalanceCard title={"Daily Users"} value={todayUsers || 0} />
            <BalanceCard title={"Weekly Users"} value={weekUsers || 0} />
            <BalanceCard title={"Monthly Users"} value={monthUsers || 0} />
            {/* <PenomoRewards /> */}

            <div className="grid grid-cols-1 gap-4 xl:col-span-1 md:grid-cols-2">
              <ProjectOverViewCard
                icon={<IcProjectCompleted />}
                count={todayUsers || 0}
                title="New Users"
              />
              <ProjectOverViewCard
                icon={<IcProjectInreview />}
                count={dashboardStates?.inReviewProjectsCount ?? 0}
                title="Not Registred Users"
              />
            </div>
          </section>

          {/* ~ user graph starts here  */}
          <div>
            <h3 className="my-3">Visual User Graph</h3>

            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={chartData || data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="users"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="users" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          {/* <div>
            <h3>New User Graph</h3>

            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <Treemap
                  width={400}
                  height={200}
                  data={data}
                  dataKey="size"
                  aspectRatio={4 / 3}
                  stroke="#fff"
                  fill="#8884d8"
                />
              </ResponsiveContainer>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default DashboardPage;
